var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        label: _vm.label,
        "label-for": "input",
        "label-class": { "required-field": _vm.required },
      },
    },
    [
      _c(
        "b-overlay",
        {
          attrs: { show: _vm.loading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c("pulse-loader", {
                    attrs: {
                      loading: _vm.loading,
                      color: _vm.fill,
                      size: "10px",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: {
                  content: _vm.error,
                  show: _vm.validate && _vm.v.$error,
                  trigger: "manual",
                  placement: "bottom",
                  classes: ["light"],
                },
                expression:
                  "{\n        content: error,\n        show: validate && v.$error,\n        trigger: 'manual',\n        placement: 'bottom',\n        classes: ['light']\n      }",
              },
            ],
            class: { "search-input": _vm.type === "search" },
            attrs: {
              type: _vm.type,
              placeholder: _vm.placeholder,
              readonly: _vm.readonly,
              autocomplete: "off",
              step: _vm.step,
            },
            on: {
              input: function ($event) {
                _vm.validate ? _vm.v.$touch() : null
              },
              focus: function ($event) {
                _vm.focus ? $event.target.select() : ""
              },
            },
            model: {
              value: _vm.name,
              callback: function ($$v) {
                _vm.name = $$v
              },
              expression: "name",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }