export function isPasswordValid(value) {
  const containsUppercase = /[A-Z]/.test(value)
  const containsLowercase = /[a-z]/.test(value)
  const containsNumber = /[0-9]/.test(value)
  const containsSpecial = /[#?!@$%^&*-]/.test(value)
  return containsUppercase && containsLowercase && containsNumber && containsSpecial
}

export const errorMessages = {
  required: 'is required',
  email: 'Email is not in a correct format',
  emailMinLength: 'Email must contain more than 4 characters',
  passwordMaxLength: 'Password must contain less than 16 characters',
  passwordMinLength: 'Password must contain more than 8 characters',
  isPasswordValid:
    'Your password must contain at least one uppercase letter, one lowercase letter, one number and one special character',
  samePassword: 'Confirm password must match the password',
  nameMinLength: 'must have at least 2 letters'
}
